<script setup lang="ts">
import { addDays } from 'date-fns';
import HeroIcon from '~/components/ui/HeroIcon.vue';
import { grantConsent, revokeConsent } from '~/utils/client/useGtagConsent';

const cookiesAccepted = useCookie<-1 | 0 | 1>('cookies', {
    default() {
        return -1;
    },
    expires: addDays(new Date(), 365),
    sameSite: 'lax',
    path: '/',
});

const onCookiesReset = () => {
    revokeConsent();
    console.info('Cookies reset');
    cookiesAccepted.value = -1;
};

const onCookiesAccepted = () => {
    grantConsent();
    cookiesAccepted.value = 1;
    console.info('Cookies accepted');
    window.location.reload(); // reload scripts after consent change
};

const onCookiesDeclined = () => {
    revokeConsent();
    cookiesAccepted.value = 0;
    console.info('Cookies declined');
    window.location.reload(); // reload scripts after consent change
};
</script>

<template>
    <div>
        <div
            v-if="cookiesAccepted < 0"
            class="flex flex-col items-center lg:flex-row justify-center lg:justify-between shadow-2xl fixed w-full z-[10000] -mt-3 p-5 bg-base-content text-gray-200"
        >
            Používáme analytické cookies, které nám pomáhají zlepšovat naše služby.
            <div class="lg:ml-auto mt-5 lg:mt-0 flex flex-row justify-center items-center">
                <NuxtLink to="/cookies" class="btn btn-link text-gray-500 btn-xs">Více informací</NuxtLink>
                <button class="btn btn-link text-gray-500 btn-xs" @click="onCookiesDeclined">Nesouhlasím</button>
                <button class="btn btn-primary btn-sm" @click="onCookiesAccepted">Souhlasím</button>
            </div>
        </div>
        <div v-else class="flex flex-row items-center justify-end w-full z-[20] -mt-3 p-2 absolute">
            <button
                class="btn btn-circle btn-ghost tooltip tooltip-left"
                data-tip="Znovu nastavit cookies"
                @click="onCookiesReset"
            >
                <HeroIcon
                    :name="cookiesAccepted > 0 ? 'FaceSmileIcon' : 'FaceFrownIcon'"
                    :size="6"
                />
            </button>
        </div>
    </div>
</template>
