<script setup lang="ts">

</script>

<template>
    <div class="mx-auto my-6 flex flex-col justify-center items-center">
        <h2
            class="text-accent relative -left-2 cursor-default text-xl md:text-2xl lg:text-3xl z-20 animate-on-scroll move-left mb-10"
        >S kým spolupracuji?</h2>
        <div class="flex flex-col md:flex-row gap-8 md:gap-16 justify-center items-center">
            <a
                href="https://www.mailerlite.com/a/dAXWMsKfNzxF"
                title="MailerLite"
                target="_blank"
                class="link link-hover hover:animate-wiggle"
            >
                <img
                    loading="lazy"
                    src="/affiliate-icons/mailerlite.png"
                    alt="MailerLite"
                    width="119"
                    height="30"
                />
            </a>
            <a
                href="https://fapi.cz/?a_box=82nftbwe"
                title="FAPI"
                target="_blank"
                class="link link-hover hover:animate-wiggle"
            >
                <img
                    loading="lazy"
                    src="/affiliate-icons/fapi.png"
                    alt="FAPI"
                    width="70"
                    height="30"
                />
            </a>
        </div>
    </div>
</template>
