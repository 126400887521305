<script setup lang="ts">
import NavLinks from '~/components/other/NavLinks.vue';

const drawerOpen = ref(false);
</script>

<template>
    <nav class="fixed w-full bg-base-100 z-[100] shadow">
        <div class="drawer drawer-end">
            <input
                id="my-drawer-3"
                type="checkbox"
                class="drawer-toggle"
                v-model="drawerOpen"
            />
            <div class="drawer-content flex flex-col">
                <!-- Navbar -->
                <div ref="navigation" class="w-full navbar">
                    <div class="flex-1 px-2 mx-2 text-accent h-full">
                        <NuxtLink to="/" class="h-full w-[100px]">
                            <img
                                class="h-[36px] object-contain my-[8px] relative hover:animate-wiggle"
                                src="/logo_wide.png"
                                alt="NajdiAsistentku.cz"
                                width="391"
                                height="140"
                            />
                        </NuxtLink>
                    </div>
                    <div class="flex-none lg:hidden">
                        <label for="my-drawer-3" class="btn btn-square btn-ghost">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                class="inline-block w-6 h-6 stroke-current"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />

                            </svg>
                        </label>
                    </div>
                    <div class="flex-none hidden lg:block">
                        <ul class="menu menu-horizontal menu-title">
                            <!-- Navbar menu content here -->
                            <NavLinks />
                        </ul>
                    </div>
                </div>
            </div>
            <div class="drawer-side overflow-hidden">
                <label for="my-drawer-3" class="drawer-overlay"></label>
                <ul class="menu p-4 w-80 h-full bg-base-200">
                    <!-- Sidebar content here -->
                    <NavLinks @click="drawerOpen = false" />
                </ul>
            </div>
        </div>
    </nav>
</template>

<style scoped lang="postcss">

</style>
