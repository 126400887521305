<script setup lang="ts">
import type { Ref } from 'vue';

const scrollTop = ref<Ref<HTMLElement> | null>(null);

onMounted(() => {
    if (import.meta.client) {
        window.addEventListener('scroll', () => {
            if (scrollTop.value) {
                if (window.scrollY > 100) {
                    scrollTop.value.classList.remove('opacity-0');
                    scrollTop.value.classList.remove('opacity-100');
                } else {
                    scrollTop.value.classList.add('opacity-0');
                    scrollTop.value.classList.remove('opacity-100');
                }
            }
        });
    }
});
</script>

<template>
    <div class="fixed md:sticky md:top-0 md:left-0 bottom-0 right-0 p-5 z-[70] lg:z-30">
        <div class="flex justify-end">
            <a
                ref="scrollTop"
                href="#"
                class="btn btn-lg btn-circle shadow-none bg-transparent hover:bg-transparent border-none transition-opacity ease-in-out duration-500 opacity-0 hover:animate-wiggle"
            >
                <img src="/icon.png" class="-rotate-90" alt="Scroll top"/>
            </a>
        </div>
    </div>
</template>
