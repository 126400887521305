<script setup lang="ts">
import { useTexts } from '~/utils/client/useTexts';

const useText = await useTexts();
</script>

<template>
    <footer class="footer p-10 bg-base-200 text-base-content items-center z-20">
        <div class="flex flex-col items-center w-full">
            <NuxtLink to="/katalog" class="link link-hover">Katalog</NuxtLink>
            <NuxtLink to="/blog" class="link link-hover">Blog</NuxtLink>
            <NuxtLink to="/nabizene-sluzby" class="link link-hover">Nabízené služby</NuxtLink>
            <NuxtLink to="/kontakt" class="link link-hover">Kontakt</NuxtLink>
            <NuxtLink to="/cenik" class="link link-hover">Ceník</NuxtLink>
            <NuxtLink to="/registrace-asistentek" class="link link-hover">Registrace asistentek</NuxtLink>
        </div>
        <div class="flex flex-col items-center w-full">
            <NuxtLink to="/">
                <img
                    class="h-[100px] object-contain hover:animate-wiggle"
                    src="/logo_tall.png"
                    alt="NajdiAsistentku.cz"
                    width="193"
                    height="258"
                />
            </NuxtLink>
        </div>
        <div class="flex flex-col items-center w-full">
            <NuxtLink to="/slovnik" class="link link-hover">Slovník</NuxtLink>
            <NuxtLink to="/obchodni-podminky" class="link link-hover">Obchodní podmínky</NuxtLink>
            <NuxtLink to="/cookies" class="link link-hover">Cookies</NuxtLink>
            <NuxtLink to="/gdpr" class="link link-hover">GDPR</NuxtLink>
            <NuxtLink to="/admin" class="link link-hover">Přihlášení</NuxtLink>
        </div>
    </footer>
    <footer class="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300 z-20 justify-center md:justify-between">
        <div class="flex flex-row flex-wrap items-center justify-center">
            <p>
                <NuxtLink to="/" class="link link-hover">NajdiAsistentku.cz</NuxtLink> {{ useText('copyright') }}
            </p>
            <p>
                vytvořili <a href="https://owebs.cz" target="_blank" class="link">Ondřej Cháb</a> a <a href="https://pavelseps.cz" target="_blank" class="link">Pavel Šeps</a>.
            </p>
        </div>
        <div class="lg:place-self-center lg:justify-self-end w-full">
            <div class="flex flex-row gap-4 w-full justify-center">
                <a class="hover:rotate-12 transition-transform" :href="useText('facebook')">
                    <svg class="w-[24px] h-[24px]" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="facebook"><path d="M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z"></path></svg>
                </a>
                <a class="hover:rotate-12 transition-transform" :href="useText('linkedin')">
                    <svg class="w-[24px] h-[24px]" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="linkedin"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path></svg>
                </a>
            </div>
        </div>
    </footer>
</template>
