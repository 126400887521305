<script setup lang="ts">
import Themes from 'daisyui/src/theming/themes';
import type { Engine } from 'tsparticles-engine';
import { loadSlim } from 'tsparticles-slim';

const particlesInit = async (engine: Engine) => {
    await loadSlim(engine);
};

const tsParticlesConfig = computed(() => ({
    fpsLimit: 60,
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: 'slow',
                parallax: {
                    enable: false,
                    force: 10,
                    smooth: 100,
                },
            },
            resize: true,
        },
        modes: {
            slow: {
                factor: 2,
                radius: 200,
            },
        },
    },
    particles: {
        color: {
            random: true,
            value: [
                Themes.cupcake.primary,
                Themes.cupcake.secondary,
                Themes.cupcake.accent,
            ],
        },
        links: {
            color: Themes.cupcake.neutral,
            distance: 100,
            enable: true,
            opacity: 0.1,
            width: 1,
        },
        move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: true,
            speed: {
                min: 2,
                max: 4,
            },
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 10,
        },
        opacity: {
            random: true,
            value: {
                min: 0.3,
                max: 0.5,
            },
        },
        shape: {
            type: 'circle',
        },
        size: {
            random: true,
            value: {
                min: 1,
                max: 10,
            },
        },
    },
    fullScreen: {
        enable: true,
        zIndex: 0,
    },
    detectRetina: true,
}));
</script>

<template>
    <vue-particles
        id="tsParticles"
        :particles-init="particlesInit"
        :options="tsParticlesConfig"
        class="absolute w-full h-full"
    />
</template>
