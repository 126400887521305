export const animateOnScroll = () => {
    if (!import.meta.client) {
        return;
    }

    for (const el of document.querySelectorAll<HTMLElement>(
        '.animate-on-scroll',
    )) {
        const animations = [
            {
                active: el.classList.contains('resize-up'),
                transform: 'scale',
                unit: '',
                min: 0.9,
                max: 1.3,
            },
            {
                active: el.classList.contains('resize-down'),
                transform: 'scale',
                unit: '',
                min: 1.3,
                max: 0.9,
            },
            {
                active: el.classList.contains('move-left'),
                transform: 'translateX',
                unit: '%',
                min: 10,
                max: 0,
            },
            {
                active: el.classList.contains('move-right'),
                transform: 'translateX',
                unit: '%',
                min: 0,
                max: 10,
            },
            {
                active: el.classList.contains('move-up'),
                transform: 'translateY',
                unit: '%',
                min: 0,
                max: -50,
            },
            {
                active: el.classList.contains('move-down'),
                transform: 'translateY',
                unit: '%',
                min: 0,
                max: 10,
            },
        ].filter((animation) => animation.active);

        const animateOnScroll = () => {
            const rect = el.getBoundingClientRect();
            const isInViewport =
                rect.top < window.innerHeight && rect.bottom > 0;
            const viewportHeight = window.innerHeight;
            const viewportOffset = Math.min(
                Math.max(0, viewportHeight - rect.top),
                viewportHeight,
            );

            if (!isInViewport) {
                return;
            }

            let transform = '';

            for (const animation of animations) {
                const {
                    transform: animationTransform,
                    unit,
                    min,
                    max,
                } = animation;
                const percentage =
                    viewportOffset > 0 ? viewportOffset / viewportHeight : 0;
                const step = percentage * (max > min ? max - min : min - max);
                const value = max > min ? min + step : min - step;
                transform += ` ${animationTransform}(${value}${unit})`;
            }

            el.style.transform = transform;
        };

        animateOnScroll();

        window.addEventListener('scroll', () => {
            animateOnScroll();
        });
    }
};
