<script setup lang="ts">
const emit = defineEmits(['click']);

const onClick = (event: Event) => {
    emit('click', event);
};
</script>

<template>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/`">Úvod</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/katalog`">Katalog</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/blog`">Blog</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/o-katalogu`">O katalogu</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/zdarma`">Zdarma</NuxtLink>
    </li>
<!--    <li class="hover:-translate-y-0.5 transition-transform">-->
<!--        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/networking`">Networking</NuxtLink>-->
<!--    </li>-->
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/nabizene-sluzby`">Nabízené služby</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/registrace-asistentek`">Registrace asistentek</NuxtLink>
    </li>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300" :to="`/kontakt`">Kontakt</NuxtLink>
    </li>
</template>

<style scoped lang="postcss">

</style>
