<script setup lang="ts">
import AffiliateLinks from '~/components/other/AffiliateLinks.vue';
import NavBar from '~/components/other/NavBar.vue';
import PageFooter from '~/components/other/PageFooter.vue';
import TsParticles from '~/components/other/TsParticles.vue';
import CookieBar from '~/components/ui/CookieBar.vue';
import ScrollTopButton from '~/components/ui/ScrollTopButton.vue';
import { correctTooltipsPosition } from '~/utils/client/useTooltips';
const router = useRouter();
watch(
    () => router.currentRoute.value,
    () => {
        setTimeout(() => {
            animateOnScroll();
            correctTooltipsPosition();
        }, 300);
    },
    { immediate: true, deep: true },
);
</script>

<template>
    <div data-theme="cupcake" class="scroll-smooth flex flex-col overflow-hidden h-full min-h-[100vh]">
        <TsParticles v-once />
        <NavBar />
        <main ref="main" class="grow relative pt-[80px] h-100 min-h-full">
            <CookieBar />
            <slot></slot>
            <AffiliateLinks/>
            <ScrollTopButton />
        </main>
        <PageFooter />
    </div>
</template>

<style lang="postcss">
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Marcellus", serif;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
}

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>
